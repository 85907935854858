import React from "react"
import {
  Box,
  Button,
  Flex,
  Image,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Link,
  IconButton,
  Heading,
  chakra,
} from "@chakra-ui/react"
import { Link as I18NextLink, useI18next } from "gatsby-plugin-react-i18next"
import { TriangleDownIcon, HamburgerIcon } from "@chakra-ui/icons"


import UserResearchLogo from "@/assets/images/userresearch/yara-logo.svg"
import {
  codeLanguageMap,
  codeLanguageAbbreviationMap,
} from "@/utils/userresearch/languages"
import { sectionHyperlinks, headerHeight } from "@/utils/userresearch/constant"

const languages = Object.entries(codeLanguageMap).map(([code, language]) => ({
  label: language,
  value: code,
}))

const Header = () => {
  return (
    <Box
      as="header"
      position="sticky"
      top="0"
      left="0"
      right="0"
      width="full"
      shadow="md"
      backgroundColor="#fff"
      zIndex="2"
    >
      <Flex
        position="relative"
        alignItems="center"
        width="full"
        maxWidth="7xl"
        height={headerHeight}
        mx="auto"
        px="6"
      >
        <LogoLink />
        <Spacer />
        <HStack spacing="10">
          <SpreadedOptions />
          <HStack spacing="3">
            <LanguageMenu />
            <OptionsHamburgerMenu />
          </HStack>
        </HStack>
      </Flex>
    </Box>
  )
}

export default Header

const LogoLink = () => (
  <Link
    as={I18NextLink}
    to="/userresearch"
    borderRadius="md"
    _hover={{ textDecoration: "none" }}
  >
    <HStack>
      <Image src={UserResearchLogo} height="2.425rem" loading="eager" />
            <Heading
                fontSize={{ base: "0.75rem", sm: "1.125rem" }}
                fontWeight="400"
                color="ahuaBlue.70"
                letterSpacing="tight"
              >
                User Research
              </Heading>
    </HStack>
  </Link>
)

const SpreadedOptions = () =>
  sectionHyperlinks.map(({ label, href, color }, index) => (
    <Link
      as={I18NextLink}
      display={{ base: "none", lg: "initial" }}
      key={`header-link-${index + 1}`}
      to={href}
      color={color || "inherit"}
      fontWeight="600"
      borderRadius="md"
    >
      {label}
    </Link>
  ))

const OptionsHamburgerMenu = () => (
  <Box display={{ base: "initial", lg: "none" }}>
    <Menu isLazy lazyBehavior matchWidth preventOverflow strategy="fixed">
      <MenuButton
        as={IconButton}
        size="sm"
        aria-label="options"
        icon={<HamburgerIcon />}
        background="inherit"
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="0.1rem"
        fontSize={{ base: "sm", sm: "inherit" }}
      />
      <MenuList>
        {sectionHyperlinks.map(({ label, href, color }, index) => (
          <MenuItem key={`header-hamburger-menu-link-${index + 1}`}>
            <Box
              as={I18NextLink}
              to={href}
              width="full"
              color={color || "inherit"}
            >
              {label}
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  </Box>
)

const LanguageMenu = () => {
  const { originalPath, language: currentLanguageCode } = useI18next()

  return (
    <Menu isLazy lazyBehavior matchWidth preventOverflow strategy="fixed">
      <MenuButton
        as={Button}
        size="sm"
        background="inherit"
        rightIcon={<TriangleDownIcon width="2" />}
        px="3"
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="0.1rem"
        fontSize={{ base: "sm", sm: "inherit" }}
      >
        {codeLanguageAbbreviationMap[currentLanguageCode]}
      </MenuButton>
      <MenuList>
        {languages.map(({ label, value }) => (
          <MenuItem key={value} isDisabled={value === currentLanguageCode}>
            <Box
              as={I18NextLink}
              to={originalPath}
              language={value}
              width="full"
            >
              {label}
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
